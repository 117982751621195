<template>
  <div id="contactContainer">
    <div class="contactDetailsContainer">
      <div class="contactDetails">
        <div>
          +974 7068 9534
          <br />info@aquastarinc.com
        </div>
      </div>
      <div class="contactDetails" style=" justify-content: flex-start;">
        <div class="leftLineForContacatDetails">
          Aquastar
          <br />Building No 05,
          <br />Shop No 29 & 31
          <br />Barwa Village, P O - 18449
          <br />Doha, Qatar
        </div>
      </div>
    </div>
    <form @submit.prevent="formSubmit">
      <h3>Contact Us</h3>
      <div class="formElement">
        <label>Name</label>
        <input type="text" name id v-model="formData.name" />
      </div>
      <div class="formElement">
        <label>Email</label>
        <input type="text" name id v-model="formData.email" />
      </div>
      <div class="formElement">
        <label>Mobile</label>
        <input type="text" name id v-model="formData.mobile" />
      </div>
      <label v-show="successMessage" id="successMessage">We will contact you soon</label>
      <button id="submitBt" v-show="this.submitShow">Submit Enquiry</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "contact",
  data() {
    return {
      formData: {},
      submitShow: true,
      successMessage: false
    };
  },
  methods: {
    formSubmit() {
      this.submitShow = false;
      axios
        .post("form.php", this.formData)
        .then(
          function(response) {
            console.log(response.data);
            if (response.data != "0") {
              this.successMessage = true;
            } else {
              this.submitShow = true;
            }
          }.bind(this)
        )
        .catch(
          function(error) {
            console.log(error);
            this.submitShow = true;
          }.bind(this)
        );
    }
  }
};
</script>

<style lang="scss" scoped>
#contactContainer {
  margin-top: $headerHeight;
  .contactDetailsContainer {
    background-color: $darkBlue;
    color: $white;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    @include media-md {
      flex-direction: row;
      padding: unset;
    }
    .contactDetails {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      height: auto;
      margin-bottom: 1rem;
      @include media-md {
        width: 50%;
        height: 300px;
        justify-content: center;
        padding: unset;
        margin-bottom: unset;
        align-items: center;
      }
      .leftLineForContacatDetails {
        @include media-md {
          justify-self: flex-start;
          padding-left: 1rem;
          border-left: 1px solid #eeeeee;
        }
      }
    }
  }
  form {
    width: 90%;
    margin: 4rem auto;
    max-width: 500px;
    h3 {
      margin: 0.5rem 0 2rem 0;
      color: $blue;
      font-weight: 400;
    }
    .formElement {
      display: flex;
      flex-direction: column;
      justify-items: flex-end;
      margin: 0.5rem 0;
      @include media-md {
        flex-direction: row;
      }
    }
    label {
      width: 90px;
      color: $darkBlue;
    }
    input,
    textarea {
      padding: 0.25rem 0.5rem;
      border: none;
      border-bottom: 1px solid $blue;
      margin: 0.5rem 0 0.5rem 0;
      flex: 1;
      @include media-md {
        margin-top: 0;
        margin-left: 0.5rem;
      }
    }
    textarea {
      height: 100px;
    }
    button {
      justify-self: center;
      margin: 2rem auto;
      width: 50%;
      padding: 0.5rem;
      background-color: $darkBlue;
    }
  }
}
</style>

